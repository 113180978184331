import { useState, useEffect } from "react";
import cn from "classnames";
import {
  initClient as initGoogleClient,
  signIn as signInToGoogle,
  signOut as signOutFromGoogle,
  getSignature,
  setSignature,
} from "../../services/google";
import "./App.scss";
import Input from "../Input/Input";
import { ICONS_URL } from "../../config";

const emailIcon = `${ICONS_URL}/email.png`;
const webIcon = `${ICONS_URL}/global.png`;
const phoneIcon = `${ICONS_URL}/phone.png`;
const logo = `${ICONS_URL}/logo2.png`;
const facebook = `${ICONS_URL}/facebook2.png`;
const linkedin = `${ICONS_URL}/linkedin2.png`;
const instagram = `${ICONS_URL}/instagram.png`;
const youtube = `${ICONS_URL}/youtube.png`;
const twitter = `${ICONS_URL}/twitter.png`;
const google = `${ICONS_URL}/google.png`;

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentSignature, setCurrentSignature] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [photoLink, setPhotoLink] = useState("");
  const [isCurrentSignatureShown, setIsCurrentSignatureShown] = useState(true);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [isSignatureLoading, setIsSignatureLoading] = useState(false);

  const signatureResult = `
  <div style="display: flex; font-family: Helvetica; color: #474E5D; max-width: 700px;">
    <div style="text-align: center; background: #D8282F; background: linear-gradient(138deg, #A01 0%, #CB2A32 65.87%, #D93C41 94.78%); padding: 40px 16px 16px;">
      <div style="padding: 3px; border: 1px solid #ffff; border-radius: 50%; width: 71px; margin: 0 auto 8px;">
        <div style="border-radius: 50%; height: 71px; width: 71px; background: url(${photoLink}) transparent no-repeat bottom center  / cover; background-size: cover; background-position: ${positionX}px ${positionY}px;">
        </div>
      </div>
      <div style="color: #FFFF; margin-bottom: 6px; font-size: 14px; font-weight: 700; line-height: normal; min-width: 96px; width: 15vw; max-width: 141px;">
        ${fullName}
      </div>
      <div style="color: #FFFF; font-size: 11px; font-weight: 400; line-height: normal; text-align: center;">
        ${position}
      </div>
    </div>
    <div style="padding: 16px 0; background: #F2F4F8; display: flex; width: 100%;">
      <div style="padding: 0 16px;">  
        <div style="margin-bottom: 16px; color: #191919; font-weight: 700; font-size: 12px;">
          <img src=${emailIcon} alt="" style="width: 14px; height: 11px; margin-right: 10px; vertical-align: middle;"/>
          <a href="" style="text-decoration: none;  color: #191919;">
            <span>${email}</span>
          </a>
        </div>
        <div style="margin-bottom: 16px; font-weight: 700; font-size: 12px; cursor: pointer;">
          <a href="https://www.steercrm.com/" target="_blank" style="text-decoration: none; color: #191919;">
            <img src=${webIcon} alt="" style="width: 14px; height: 14px; margin-right: 10px; vertical-align: middle;"/>
            <span>www.steercrm.com</span>
          </a>
        </div>
        <div style="margin-bottom: 16px; color: #191919; font-weight: 700; font-size: 12px;">
          <img src=${phoneIcon} alt="" style="width: 14px; height: 14px; margin-right: 10px; vertical-align: middle;"/>
          <span>${phone}</span>
        </div>
        <div style="margin-top: 16px; height: 32px;">
          <a href="https://steercrm.com/" target="_blank" style="text-decoration: none; color: #A4ADBB;">
            <img src=${logo} alt="" style="width: 183px; height: 32px;"/>
          </a>
        </div>
        <div style="display: inline-block; margin: 16px 8px 0 0; vertical-align: middle; height: 32px;">
          <a href="https://www.facebook.com/steercrm/" target="_blank" style="text-decoration: none; color: #A4ADBB;">
            <img src=${facebook} alt="" style="width: 32px; height: 32px;"/>
          </a>
        </div>
        <div style="display: inline-block; font-size: 12px; margin: 16px 8px 0 0; vertical-align: middle; height: 32px;">
          <a href="https://www.linkedin.com/company/steercrm" target="_blank" style="text-decoration: none; color: #A4ADBB;">
            <img src=${linkedin} alt="" style="width: 32px; height: 32px;"/>
          </a>
        </div>
        <div style="display: inline-block; font-size: 12px; margin: 16px 8px 0 0; vertical-align: middle; height: 32px;">
          <a href="https://www.instagram.com/steercrm/" target="_blank" style="text-decoration: none; color: #A4ADBB;">
            <img src=${instagram} alt="" style="width: 32px; height: 32px;"/>
          </a>
        </div>
        <div style="display: inline-block;  margin: 16px 8px 0 0; vertical-align: middle; height: 32px;">
          <a href="https://www.youtube.com/@mechanicadvisor" target="_blank" style="text-decoration: none; color: #A4ADBB;">
            <img src=${youtube} alt="" style="width: 32px; height: 32px;"/>
          </a>
        </div>
        <div style="display: inline-block; margin: 16px 8px 0 0; vertical-align: middle; height: 32px;">
          <a href="https://twitter.com/steercrm" target="_blank" style="text-decoration: none; color: #A4ADBB;">
            <img src=${twitter} alt="" style="width: 32px; height: 32px;"/>
          </a>
        </div>
        <div style="display: inline-block; font-size: 12px; margin: 16px 0px 0 0; vertical-align: middle; height: 32px;">
          <a href="https://www.google.com/maps/place/Steer+by+Mechanic+Advisor/@42.3379379,-71.0350885,15z/data=!4m6!3m5!1s0x89e37bb4dac36657:0xcbc010aca77291af!8m2!3d42.3379379!4d-71.0350885!16s%2Fg%2F11t9b006r6?entry=ttu" target="_blank" style="text-decoration: none;  color: #A4ADBB;">
            <img src=${google} alt="" style="width: 32px; height: 32px;"/>
          </a>
        </div>
      </div>
    </div>
  </div>`;

  useEffect(() => {
    initGoogleClient(() => setIsLoggedIn(true));
  }, []);

  useEffect(() => {
    async function fetchSignature() {
      if (isLoggedIn) {
        const { email, signature } = await getSignature();
        setEmail(email);
        setCurrentSignature(signature);
      }
    }
    fetchSignature();
  }, [isLoggedIn]);

  const handleSignOut = () => {
    signOutFromGoogle();
    setIsLoggedIn(false);
  };

  const updateSignature = async () => {
    setIsSignatureLoading(true);
    const result = await setSignature(email, signatureResult);
    if (result) {
      setCurrentSignature(result);
      setIsCurrentSignatureShown(true);
      setIsSignatureLoading(false);
    }
  };

  function handlePhoneChange(value) {
    const maskedValue = value
      .replace(/^(\d)/, "($1")
      .replace(/^(\(\d{3})(\d)/, "$1) $2")
      .replace(/^(\(\d{3}\) \d{3})(?=\d)/, "$1-")
      .replace(/^(\(\d{3}\) \d{3}-\d{4})(?=\S)/, "$1 ");

    setPhone(maskedValue);
  }

  return (
    <div className="app">
      {isLoggedIn ? (
        <>
          <header className="app-header">
            <div className="header-text">Email signature settings</div>
            <button className="main-button" onClick={handleSignOut}>
              Sign Out
            </button>
          </header>
          <div className="form-container">
            <Input
              value={fullName}
              name="fullName"
              id="fullName"
              label="Full name"
              onChange={(e) => {
                setIsCurrentSignatureShown(false);
                setFullName(e.target.value);
              }}
            />
            <div className="input-row">
              <Input
                value={email}
                onChange={(e) => {
                  setIsCurrentSignatureShown(false);
                  setEmail(e.target.value);
                }}
                name="email"
                id="email"
                label="Email"
              />
              <Input
                name="phone"
                id="phone"
                label="Phone"
                value={phone}
                onChange={(e) => {
                  setIsCurrentSignatureShown(false);
                  handlePhoneChange(e.target.value);
                }}
              />
            </div>
            <Input
              name="position"
              id="position"
              label="Position"
              value={position}
              onChange={(e) => {
                setIsCurrentSignatureShown(false);
                setPosition(e.target.value);
              }}
            />
            <div className="input-row">
              <Input
                name="photo"
                id="photo"
                value={photoLink}
                onChange={(e) => {
                  setIsCurrentSignatureShown(false);
                  setPhotoLink(e.target.value);
                }}
                label="Person photo link (use the right side buttons to even out the photo)"
                placeholder="Paste link here"
              />
              <div className="move-buttons">
                <div
                  className="arrow-button"
                  onClick={() => setPositionX((prev) => prev - 5)}
                ></div>
                <div
                  className="arrow-button"
                  onClick={() => setPositionY((prev) => prev - 5)}
                ></div>
                <div
                  className="arrow-button"
                  onClick={() => setPositionY((prev) => prev + 5)}
                ></div>
                <div
                  className="arrow-button"
                  onClick={() => setPositionX((prev) => prev + 5)}
                ></div>
              </div>
            </div>
            <Input
              label="Generated code"
              value={signatureResult}
              name="resultContainer"
              multiline
            ></Input>
            <div className="form-button">
              <button
                onClick={() => {
                  updateSignature();
                }}
                className="main-button"
              >
                {isSignatureLoading ? (
                  <div className="loader"></div>
                ) : (
                  "Set Signature"
                )}
              </button>
            </div>
          </div>
          <div className="preview-container">
            <div className="preview-header-menu">
              <div
                className={cn("preview-menu-item", {
                  ["active-item"]: isCurrentSignatureShown,
                })}
                onClick={() => {
                  setIsCurrentSignatureShown(true);
                }}
              >
                Current Signature
              </div>
              <div
                className={cn("preview-menu-item", {
                  ["active-item"]: !isCurrentSignatureShown,
                })}
                onClick={() => {
                  setIsCurrentSignatureShown(false);
                }}
              >
                New signature preview
              </div>
            </div>
            {!isCurrentSignatureShown && (
              <div dangerouslySetInnerHTML={{ __html: signatureResult }}></div>
            )}
            {isCurrentSignatureShown && (
              <div dangerouslySetInnerHTML={{ __html: currentSignature }}></div>
            )}
          </div>
        </>
      ) : (
        <div className="signIn-button">
          <button className="main-button" onClick={signInToGoogle}>
            Sign In
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
