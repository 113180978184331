import "./Input.scss";

export default function Input({
  value = "",
  onChange,
  label,
  name,
  id,
  multiline,
  placeholder,
}) {
  return (
    <div className="input">
      <div className="label">{label}</div>
      {!multiline && (
        <input
          id={id}
          value={value}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
        />
      )}
      {multiline && (
        <textarea
          id={id}
          value={value}
          name={name}
          onChange={onChange}
          rows={5}
          readOnly
        />
      )}
    </div>
  );
}
