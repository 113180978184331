import axios from "axios";
import { CLIENT_ID } from "../../config";

const SCOPES = "https://www.googleapis.com/auth/gmail.settings.basic";

const google = window.google;
let client;
let access_token;

export function initClient(onLogin) {
  client = google.accounts.oauth2.initTokenClient({
    client_id: CLIENT_ID,
    scope: SCOPES,
    callback: (tokenResponse) => {
      if (tokenResponse.error) {
        console.log(tokenResponse.error);
      } else {
        access_token = tokenResponse.access_token;
        onLogin();
      }
    },
  });
}

export function signIn() {
  client.requestAccessToken();
}

export function signOut() {
  google.accounts.oauth2.revoke(access_token);
}

export async function getSignature() {
  try {
    const res = await axios({
      method: "get",
      url: "https://gmail.googleapis.com/gmail/v1/users/me/settings/sendAs/",
      headers: { Authorization: `Bearer ${access_token}` },
    });
    const { sendAsEmail: email, signature } = res.data.sendAs.find(
      (x) => x.isPrimary
    );
    return { email, signature };
  } catch (e) {
    console.log(e.message);
    return {};
  }
}

export async function setSignature(email, signature) {
  try {
    const res = await axios({
      method: "patch",
      url: `https://gmail.googleapis.com/gmail/v1/users/me/settings/sendAs/${email}`,
      headers: { Authorization: `Bearer ${access_token}` },
      data: {
        signature,
      },
    });
    return res.data.signature;
  } catch (e) {
    console.log(e.message);
    return false;
  }
}
